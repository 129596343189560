body {
    font-family: 'Karla', sans-serif;
    background-color: #f2f3f8;
}

/* Wizard */

.wizard {

    a:hover {
        text-decoration: none;
    }

    .audible {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }

    .steps {

        &>ul {
            list-style: none;
            padding-left: 0;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;

            li {
                width: 270px;
                max-width: calc(25% - 6px);

                a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    background-color: rgba(153, 155, 163, 0.1);
                    padding: 28px 30px 24px;

                    @media (max-width: 767px) {
                        padding: 15px 10px;
                    }

                    .media {
                        @media (max-width: 767px) {
                            display: block;
                        }
                    }
                }

                .bd-wizard-step-icon {
                    width: 34px;
                    height: 34px;
                    border-radius: 4px;
                    background-color: #e1e1e1;
                    font-size: 14px;
                    line-height: 34px;
                    text-align: center;
                    color: #4a2950;
                    margin-right: 11px;

                    @media (max-width: 767px) {
                        margin: 0 auto;
                    }
                }
        
                .bd-wizard-step-title {
                    line-height: 1;
                    font-size: 18px;
                    font-weight: bold;
                    color: #000000;

                    @media (max-width: 767px) {
                        display: none;
                    }
                }
        
                .bd-wizard-step-subtitle {
                    line-height: 1;
                    font-size: 14px;
                    color: #c8c8c8;

                    @media (max-width: 767px) {
                        display: none;
                    }
                }

                &.current,
                &.done {
                    .bd-wizard-step-icon {
                        background-color: #4a2950;
                        color: #fff;
                    }
                }

                &.current {
                    a {
                        background-color: #fff;
                    }
                }
            }
        }
    }

    .content {
        padding: 100px 35px 20px 35px;
        background-color: #fff;
        min-height: 420px;

        @media (max-width: 767px) {
            padding-left: 20px;
            padding-right: 20px;
            min-height: auto;
        }

        .title {
            display: none;
        }

        .content-wrapper {
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
        }

        .section-heading {
            font-weight: bold;
            color: #030303;
            margin-bottom: 22px;
        }

        p {
            font-size: 16px;
            color: #030303
        }

        .form-control {
            padding: 26px 25px;
            min-height: 50px;
            max-width: 550px;
            border-radius: 4px;
            border: solid 1px #ececec;

            &::placeholder {
                color: #919aa3;
            }
        }
    }

    .actions {
        padding: 0 35px 35px;
        background-color: #fff;

        @media (max-width: 767px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        &>ul {
            list-style: none;
            padding-left: 0;
            display: flex;
            justify-content: flex-end;
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 15px;
            border-bottom: 1px solid #f5f5f4;
        }

        li {
            a {
                display: inline-block;
                border-radius: 6px;
                background-color: #00d69f;
                padding: 16px 30px;
                color: #fff;
                font-style: 15px;
                font-weight: bold;
            }

            &.disabled {
                display: none;
            }

            &:not(.disabled)+li,
            &:not(:first-child):last-child {
                margin-left: 15px;
            }
        }
    }
}